<template>
  <a-layout-content>
    <div class="nav">
      <Breadcrumb />
    </div>
    <div class="content-container">
      <a-form class="advanced-search-form" :form="form">
        <a-row>
          <a-col :span="10">
            <a-form-item label="手续费外扣专用账户准备金（元）">
              <a-input-number
                allow-clear
                :min="0"
                :precision="2"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="10">
            <a-form-item label="商户提现手续费率">
              <a-input-number
                :min="0"
                :max="100"
                :formatter="value => `${value}%`"
                :parser="value => value.replace('%', '')"
                style="width: 100%"
              ></a-input-number>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <a-button type="primary" class="btn--save">保存</a-button>
    </div>
  </a-layout-content>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  name: "SystemSet",
  components: {
    Breadcrumb
  },
  data() {
    return {
      form: this.$form.createForm(this)
    };
  }
};
</script>

<style scoped>
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}

/deep/ .ant-form-item .ant-form-item-label {
  min-width: 225px !important;
  text-align: left !important;
  font-size: 18px;
  font-weight: bold;
}

.btn--save {
  margin-top: 20px;
}
</style>
